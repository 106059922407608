.video-full-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 300px;
    background-color: #000;
    color: white;
    box-sizing: border-box;
    * {
        box-sizing: inherit;
    }
}

.video-full {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-flipped {
    transform: translate(-50%, -50%) scaleX(-1);
}