@use "color2" as color;

// Circular buttons
.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
}
.btn-circle.btn-md {
    width: 40px;
    height: 40px;
    padding: 7px 10px;
    border-radius: 20px;
    font-size: 10px;
    text-align: center;
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 8px 13px;
    border-radius: 28px;
    font-size: 11px;
    text-align: center;
  }
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
}

.btn-loading .spinning {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }

.rounded-4 {
    border-radius: 4px;
}

.rounded-5 {
    border-radius: 10px !important;
}

.rounded-top-5 {
    border-radius: 10px 10px 0px 0px !important;
}

.rounded-bottom-5 {
    border-radius: 0px 0px 10px 10px !important;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.video-carousel .carousel-indicators {
    bottom: unset;
    top: 0px;
}

.video-carousel .carousel-indicators [data-bs-target] {
    background-color: color.$rgba-white-0;
    background-clip: padding-box;
    opacity: 1;
    margin-right: 1px;
    margin-left: 1px;
    width: 25%;
}

.video-carousel .carousel-indicators .active~[data-bs-target] {
    background-color: color.$rgba-white-2;
}

.video-carousel .carousel-indicators .active {
    --percent: calc(var(--progress) * 100%);
    background: linear-gradient(90deg, color.$rgba-white-0 var(--percent), color.$rgba-white-2 var(--percent));
    background-clip: padding-box;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(180,180,180, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}